.AppointmentIdCss {
    display: flex;
    flex-direction: column;
}

.card {
    border-radius: 15px;
    margin-bottom: 20px;
}

.dataWithImage {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.card-title {
    font-size: 14px;
    font-weight: bold;
}

.card-text {
    font-size: 12px;
}

.image-container {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.image-container img {
    max-width: 100%;
    height: auto;
    border-radius: 15px;
}

.data-container {
    flex: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.alert {
    font-size: 12px;
}

.backButton {
    align-self: center;
}

@media (max-width: 768px) {
    .card-body {
        flex-direction: column;
        align-items: flex-start;
    }

    .image-container {
        width: 100%;
        margin-top: 15px;
    }
}
