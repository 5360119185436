.react-calendar__navigation button {
    color: black;
    font-weight: bold;
    font-size: 14px;
    height: auto;
}

.bg-body {
    width: 99%;
    height: 99%;
    margin: auto;
}

.react-calendar__tile {
    position: relative;
    color: black; 
    font-size: 15px;
}

.react-calendar__month-view__days__day--weekend {
    color: red;
}

.react-calendar__tile--now {
    background: #f0f8ff; 
    color: black;
}

.react-calendar__tile .appointment-indicator {
    height: 8px;
    width: 8px;
    background-color: red;
    border-radius: 50%;
    position: absolute;
    top: 2px;
    right: 2px;
}

.react-calendar__month-view__days__day--neighboringMonth {
    color: grey;
}
