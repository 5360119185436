.custom-select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    padding: 10px 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    background-color: #fff;
}

.custom-select:focus {
    outline: none;
    border-color: #66afe9;
    box-shadow: 0 0 10px rgba(0, 153, 255, 0.5);
}

button[type="submit"],
#formConfirmPassword .btn-display-password {
    margin-top: 10px;
}

.mt-5 {
    color: #66afe9;
}
