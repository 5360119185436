.navbar-custom {
    background-color: #bbdefb;
    color: white;
    padding: 0.5rem 1rem;
}

.navbar-custom .navbar-toggler {
    border: none;
    color: white;
    order: -1;
    width: 50px;
}

.navbar-custom .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30' width='30' height='30'%3e%3cpath stroke='rgba(255, 255, 255, 0.7)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-custom .navbar-toggler:focus {
    outline: none;
    color: white;
}

.navbar-custom .navbar-nav .nav-link {
    color: white;
    padding: 5px 10px;
    align-self: center;
    font-weight: bold;
    transition: color 0.3s ease;
}

.navbar-custom .navbar-nav .nav-link:hover {
    color: #dfe3ee;
}

.navbar-custom .navbar-brand-custom {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
}

.logo-custom {
    margin-top: -2px;
    margin-bottom: -2px;
    width: 40px;
    height: 40px;
}

@media (max-width: 991.98px) {
    .navbar-custom .navbar-nav .nav-link {
        padding-left: 0;
        padding-right: 15px;
    }
}

.navbar-custom .navbar-brand-custom {
    font-size: 1.5rem;
    font-weight: bold;
    color: white;
}

.navbar-custom .navbar-collapse {
    flex-grow: 0;
}

.navbar-custom .navbar-toggler {
    margin-right: 15px;
}

.button {
    padding: 5px;
    border: none;
    border-radius: 4px;
    background-color: #1976d2;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
    width: 25%;
    max-width: 100px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}