.Login {
    min-height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: linear-gradient(to right, #e3f2fd, #bbdefb);
    color: #333;
}

.language-select {
    width: 120px;
}

.language {
    display: flex;
    justify-content: center;
    margin: 10px;
}

form {
    width: 250%;
    max-width: 400px;
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    color: #333;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.rowLoginPage {
    width: 75%;
    max-width: 300px;
}

form label {
    font-weight: bold;
    color: #333;
    align-self: flex-start;
}

form input[type="text"],
form input[type="password"] {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    margin-bottom: 20px;
}

button {
    padding: 10px;
    border: none;
    border-radius: 4px;
    background-color: #1976d2;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
    width: 100%;
    margin: 5px 0;
    max-width: 220px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.submitButton {
    width: 120px;
}

button:hover {
    background-color: #1565c0;
    transform: scale(1.05);
}

.secondary {
    background-color: #64b5f6;
    color: #fff;
    align-self: center;
}

.secondary:hover {
    background-color: #42a5f5;
}

button.primary {
    background-color: #1976d2;
}

button.primary:hover {
    background-color: #1565c0;
}

button.secondary.mb-2 {
    margin-bottom: 10px;
}

.logo {
    max-width: 150px;
    max-height: 150px;
    margin-bottom: 30px;
    width: 120px;
    height: 120px;
}

