.card-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: white;
    border-radius: 8px;
    padding: 5px;
}

.cardBody {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.dataWithImage {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.data {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.card-title, .card-text {
    margin: 5px 0;
    text-align: left;
    color: #333;
}

.img-thumbnail {
    max-width: 200px;
    max-height: 200px;
    border-radius: 8px;
    margin-left: 20px;
}

.card-button-group {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 10px;
    width: 100%;
}

.card-button-group .btn {
    flex: 1;
    margin: 10px;
    max-width: 100px;
    padding: 10px;
    border: none;
    border-radius: 4px;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.card-button-group .btn-danger {
    background-color: #e57373;
}

.card-button-group .btn-danger:hover {
    background-color: #d32f2f;
    transform: scale(1.05);
}

.card-button-group .btn-success {
    background-color: #81c784;
}

.card-button-group .btn-success:hover {
    background-color: #388e3c;
    transform: scale(1.05);
}

.card-button-group .btn-secondary {
    background-color: #64b5f6;
    color: white;
}

.card-button-group .btn-secondary:hover {
    background-color: #42a5f5;
    transform: scale(1.05);
}

.modal-footer .btn {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

.modal-footer .btn-secondary {
    background-color: #64b5f6;
    color: white;
}

.modal-footer .btn-secondary:hover {
    background-color: #42a5f5;
    transform: scale(1.05);
}

.modal-footer .btn-primary {
    background-color: #1976d2;
    color: white;
}

.modal-footer .btn-primary:hover {
    background-color: #1565c0;
    transform: scale(1.05);
}

@media (max-width: 600px) {
    .dataWithImage {
        flex-direction: column;
        align-items: center;
    }

    .card-button-group {
        flex-direction: column;
        align-items: center;
    }

    .card-button-group .btn {
        max-width: none;
        width: 100%;
        margin: 5px 0;
    }
}

