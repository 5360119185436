.contact-us-container {
    max-width: 600px;
    margin: auto;
    padding: 20px;
    border-radius: 0.375rem;
    background-color: #ffffff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.contact-form {
    width: 100%;
    max-width: 350px;
    padding: 20px;
    background-color: #f7f7f7;
    border: 1px solid #ced4da;
    border-radius: 0.375rem;
    margin-top: 20px;
    text-align: left;
}

.contact-form .form-group {
    margin-bottom: 1.5rem;
}

.contact-form .form-label {
    font-weight: bold;
    margin-bottom: 2px;
    margin-top: 10px;
    display: block;
    text-align: center;
    align-self: center;
}

.contact-form .form-control {
    border-radius: 0.375rem;
    width: 100%;
}

.contact-form .btn {
    font-size: 16px;
    font-weight: bold;
    width: 100%;
}

@media (min-width: 768px) {
    .contact-us-container {
        width: 50%;
    }

    .contact-form {
        max-width: 100%;
    }
}

