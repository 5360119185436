.booking {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: -50px;
}

.booking-form {
    width: 100%;
    max-width: 600px;
    padding: 20px;
    border: 1px solid #ced4da;
    border-radius: 0.375rem;
    background-color: #f7f7f7;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 0 auto;
    text-align: center;
}

.booking-form .form-group {
    margin-bottom: 15px;
}

.booking-form label {
    font-weight: bold;
    display: block;
    width: 100%;
    margin-top: 5px;
    margin-bottom: 0px;
}

.booking-form .form-control {
    border-radius: 0.375rem;
    width: 280px;
    max-width: 100%;
    margin: 0 auto;
}

.booking-form .form-control:focus {
    border-color: #80bdff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.25);
}

.booking-form .btn-primary {
    background-color: #007bff;
    border-color: #007bff;
}

.booking-form .btn-primary:hover {
    background-color: #0069d9;
    border-color: #0062cc;
}

.booking-form .btn-secondary {
    background-color: #6c757d;
    border-color: #6c757d;
}

.booking-form .btn-secondary:hover {
    background-color: #5a6268;
    border-color: #545b62;
}

.booking-form .alert {
    margin-top: 15px;
}

.booking-form .image-container {
    margin-top: 10px;
    text-align: center;
}

.booking-form .image-container img {
    max-width: 100%;
    height: auto;
    border-radius: 0.375rem;
}

.bookingTime {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 14px;
    padding-bottom: 40px;
    margin-top: 5px;
}

.bookingTime .form-check {
    flex: 0 1 calc(33.333% - 10px);
    margin-bottom: 10px;
    text-align: center;
}

.language {
    margin-bottom: 5px;
    margin-top: 0px;
}

.bookingH2 {
   padding: 10px 0px;
}

@media (min-width: 768px) {
    .booking-form {
        width: 50%;
    }
}


